import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import { Title, TwoColContainer, Col1Image, Col2Info, NavLink, StandardLink } from '../components/Layout/pageParts';
import { Colours } from '../components/Layout/colours';
import Layout from "../components/Layout/layout"

const RootContainer = styled.div`
    display: flex;    
    flex-direction: column;
    justify-content: space-between;   
         

    @media screen and  (min-width: 1200px){
      flex-direction: row;            
    }
`;

const Step = styled.div`
    font-size: 16px;   
    font-weight: 400;
    margin-right: 10px;
    min-width: 150px;    
`;

const StepDetails = styled.div`
    font-size: 16px;   
    font-weight: 300;
    margin-bottom:20px;    
    flex: 1;
`;

const Line = styled.hr` 
    flex:1;          
    vertical-align:bottom;
    border: none;
    height: 1px;    
    margin-bottom: 1px;
    background: ${Colours.Granite};
`;

const JoindoTERRA = styled.span`
  color: #babb00;
  font-weight: bold;
`;

const BoldText = styled.span`  
  font-weight: bolder;
`;

const JoinUsPage = () => {

    const data = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "JoinUs.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }
    }
`)

    
    return (
        <Layout>
             <RootContainer>
      <Col1Image>
        <Img alt="join us" fluid={data.fileName.childImageSharp.fluid} style={{ width: "100%", height: "100%" }} imgStyle={{ objectPosition: 'left' }} />
      </Col1Image>
      <Col2Info>
      <TwoColContainer>       
        <StepDetails>I will make getting started with doTERRA easy, just follow the steps below. If you have any questions or
         are unsure about any part of the process then <NavLink to="/contact">reach out to me</NavLink>. When you open up a wholesale
          membership you'll be saving yourself 25% off the retail prices, getting access to the FREE product of the month, earning loyalty rewards
          and much more. I'll be there for you as your wellness consultant, we're in this together. You will receive a welcome gift along with lots
          of tips and tricks in my monthly newsletter. I'll invite you to my private facebook group where you can reach out to other like minded people in our tribe.
        </StepDetails>
      </TwoColContainer> 
      <Title>Decide which option suits you best</Title>
      <TwoColContainer>
        <Step>Option 1</Step>
        <StepDetails>Purchase a starter kit this includes your membership fee, gives you a big discount on the oils included and ultimately is the best value for money.
          This would be my recommended option for most people. There are a number of different 
          <StandardLink href="https://www.doterra.com/GB/en_GB/pl/enrolment-kits"> starter kits</StandardLink> but I find the 
          best option for most people is the <StandardLink href="https://www.doterra.com/GB/en_GB/p/home-essentials-kit">Home Essentials Kit</StandardLink>. Why? Well it offers incredible value for money and gives you everything you need to
          get started, including Frankinsense which is a wonder oil.
        </StepDetails>
      </TwoColContainer>   
      <TwoColContainer>
        <Step>Option 2</Step>
        <StepDetails>Pay a one-off membership fee of £24 and receive oils at wholesale prices (25% discount) for a whole year.
          You can renew your membership in subsequent years for just £15. You can now choose the products you like from the 
          <StandardLink href="https://media.doterra.com/gb/en/brochures/product-guide.pdf"> product guide</StandardLink>. You can also find the 
          <StandardLink href="https://media.doterra.com/gb/en/forms/price-list.pdf"> price guide</StandardLink> here with retail and wholesale prices including VAT. It's worth noting
          that you'll often see wholesale prices quoted without VAT in other sources so always check.
        </StepDetails>
      </TwoColContainer>         
      <Line />        
      <TwoColContainer>
        <Step>Steps to join</Step>
        <StepDetails>
        <ul>
          <li>Head over to <StandardLink href="https://www.mydoterra.com/gemmaglover/#/joinAndSave"> my doTERRA Shop</StandardLink></li>          
          <li>Scroll down to the bottom of the page and click the <JoindoTERRA>JOIN doTERRA button</JoindoTERRA></li>                    
          <li>Choose your preferred language and shipping location</li>
          <li><BoldText>Wholesale Customer</BoldText> should be selected automatically, if not choose this option and hit continue</li>
          <li>Fill out your personal details</li>
          <li>The Sponser ID should be<BoldText> 2852747</BoldText> and the Sponser name will be Gemma Glover</li>
          <li>Continue to the next step to Select an Enrollment Kit (or membership only)</li>
          <li>Any of doTERRA’s enrollment kits automatically include your wholesale membership fee (£24). I'd recommend the <StandardLink href="https://www.doterra.com/GB/en_GB/p/home-essentials-kit">Home Essentials kit</StandardLink> for most people.
            If you don't want to go with a kit then select <BoldText>doTerra Essentials booklet + Enrollment fee (£24)</BoldText>.
            You can scroll through to see all the different kits. Please note the prices here are wholesale <BoldText>without VAT</BoldText> which
            will be added later.
          </li>
          <li>
            Add any additional products you would like. I'd suggest adding a bottle of Fractionated Coconut oil so you can safely dilute your oils for topical use.
          </li>
          <li>Select your preferred shipping method and click view totals (without VAT)</li>
          <li>Click Process Order and await for the goodness to arrive! I will be in touch very shortly with your welcome
            pack and start you on this amazing journey. Congratulations you are part of our wonderful tribe.
          </li>
        </ul>
        </StepDetails>
      </TwoColContainer>    
      </Col2Info>
    </RootContainer>
        </Layout>
)
};

export default JoinUsPage;